(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/campaign-handler/assets/javascripts/get-campaign-status.js') >= 0) return;  svs.modules.push('/components/lb-utils/campaign-handler/assets/javascripts/get-campaign-status.js');

'use strict';

const {
  subMinutes, isToday, differenceInMinutes
} = typeof exports === 'object' ? require('date-fns') : dateFns;

const { AVAILABLE_PRODUCTS,
  CAMPAIGN_REWARD,
  CAMPAIGNS,
  CAMPAIGN_EXPIRATION_DAYS,
  CAMPAIGN_AVAILABLE,
  CAMPAIGN_DONE,
  CAMPAIGN_UNAVAILABLE } = svs.isServer ? require('../../actions/constants.js') : svs.components.lbUtils.campaignHandler.constants;
const {
  parseCampaignStatus,
  parseCampaignBonus,
  parseCampaignBonusArray
} = svs.isServer ? require('../../actions/parse-campaign-status.js') : svs.components.lbUtils.campaignHandler.parseCampaignStatus;

let jupiter;
let logger;
let userSession;

if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('component:campaign-handler');
  jupiter = trinidad.jupiter;
  userSession = trinidad.userSession;
} else {
  jupiter = svs.core.jupiter;
  const log = svs.core.log;
  userSession = svs.core.userSession;
  logger = log.getLogger('component:campaign-handler');
}

let resolveTimezone;
if (!svs.isServer) {
  resolveTimezone = svs.components.lbUtils.helpers.formatDate;
}

const fetchCampaign = async (serverReq) => {
  if (!svs.isServer) {
    return new Promise((resolve, reject) => {
      jupiter.get(
        `/player/2/customizedsettings`,
        (response) => {
          if (response.customizedSettings && response.customizedSettings.properties) {
            resolve(response.customizedSettings.properties);
          }
        },
        (error) => {
          logger.info('Fetch campaignstatus err', error);
          reject(error);
        }
      );
    });
  }
  let data = await jupiter.callAsync({
    method: 'GET',
    path: '/player/2/customizedsettings',
    req: serverReq
  });
  if (data && data.customizedSettings && data.customizedSettings.properties) {
    return data.customizedSettings.properties;
  }
  return undefined;
};

const fetchBonus = async (req) => {
  if (!svs.isServer) {
    return new Promise((resolve, reject) => {
      jupiter.get(
        `/player/1/bonuses`,
        (response) => {
          resolve(response);
        },
        (error) => {
          logger.info('Fetch campaignstatus err', error);
          reject(error);
        }
      );
    });
  }
  let data = await jupiter.callAsync({
    method: 'GET',
    path: '/player/1/bonuses',
    req
  });
  return parseCampaignBonusArray(data);
};

const fetchCampaignStatus = async (serverReq) => {
  const data = await fetchCampaign(serverReq);
  const campaignStatus = data.campaignStatus || null;

  campaignStatus && logger.info('fetchCampaignStatus: (client)', campaignStatus);
  return campaignStatus;
};

const getCampaignStatus = (parsedCampaignData) => {
  if (parsedCampaignData) {
    const { expirationDate } = parsedCampaignData;
    if (!expirationDate) {
      return { availability: CAMPAIGN_UNAVAILABLE };
    }

    const todaysDate = svs.isServer ? new Date() : resolveTimezone(new Date());
    const isPostExpiration = todaysDate.getTime() > expirationDate.getTime();

    if (parsedCampaignData.isCampaignUsed) {
      return {
        availability: CAMPAIGN_DONE,
        isUsedWithin24Hours: isToday(expirationDate) || differenceInMinutes(todaysDate, expirationDate) < 60
      };
    } else if (isPostExpiration) {
      return { availability: CAMPAIGN_UNAVAILABLE };
    }
    return {
      availability: CAMPAIGN_AVAILABLE,
      expirationDate: subMinutes(expirationDate, 30)
    };
  }

  return { availability: CAMPAIGN_UNAVAILABLE };
};

const updateSessionData = async (campaignStatus) => {
  return new Promise((resolve, reject) => {
    if (svs.isServer) {
      resolve(userSession.set('campaignData', campaignStatus));
    } else {
      userSession.set('campaignData', campaignStatus, (err, res) => {
        if (err) {
          logger.info('Set campaignstatus err sessionstorage', err);
          resolve();
        }
        resolve(res);
      });
    }
  });
};

const getSessionData = async (serverReq) => {
  return new Promise((resolve, reject) => {
    if (svs.isServer) {
      const campaignData = userSession.get('campaignData');
      campaignData &&
        logger.info(
          'getSessionData: (server)',
          campaignData,
          serverReq
        );
      resolve(campaignData);
    } else {
      userSession.get('campaignData', (err, res) => {
        if (err) {
          logger.info('Fetch campaignstatus err sessionstorage', err);
          reject();
          return;
        }
        res && logger.info('getSessionData: (client)', res);
        resolve(res);
      });
    }
  });
};

const getCampaign = async ({ forceFetch = false, serverReq, onlyAvailability = true }) => {
  try {
    let parsedCampaignData;
    let campaignDataFromSession;
    if (svs.isServer) {
      if (!serverReq) {
        logger.error('Serverreq is required for getCampaign serverside!');
        return { availability: CAMPAIGN_UNAVAILABLE };
      }
      userSession = serverReq.userSession;
    }
    if (!forceFetch) {
      campaignDataFromSession = await getSessionData(serverReq);
    }

    if (forceFetch || !campaignDataFromSession) {
      const status = await fetchCampaignStatus(serverReq);
      if (status) {
        await updateSessionData(status); 
      }
      parsedCampaignData = parseCampaignStatus(status);
    } else {
      parsedCampaignData = parseCampaignStatus(campaignDataFromSession);
    }

    return onlyAvailability ? getCampaignStatus(parsedCampaignData) : parsedCampaignData;
  } catch (err) {
    return onlyAvailability ?
      { availability: CAMPAIGN_UNAVAILABLE } :
      {
        campaign: null,
        isCampaignAvailable: false,
        isCampaignUsed: false
      };
  }
};

const getCampaignBonus = async ({ serverReq }) => {
  try {
    if (svs.isServer) {
      if (!serverReq) {
        logger.error('ServerReq is required for getCampaignBonus serverside!');
        return { availability: CAMPAIGN_UNAVAILABLE };
      }
    }
    const customizedSettings = await fetchCampaign(serverReq);

    const parsedCampaignBonusData = parseCampaignBonus(customizedSettings);
    return parsedCampaignBonusData;
  } catch (err) {
    logger.error(`Get campaign bonus error: ${err.message}`);
    return { error: err };
  }
};

if (svs.isServer) {
  module.exports = {
    constants: {
      AVAILABLE_PRODUCTS,
      CAMPAIGN_REWARD,
      CAMPAIGNS,
      CAMPAIGN_EXPIRATION_DAYS,
      CAMPAIGN_AVAILABLE,
      CAMPAIGN_DONE,
      CAMPAIGN_UNAVAILABLE
    }, getCampaign, fetchBonus, getCampaignBonus, parseCampaignStatus: require('../../actions/parse-campaign-status.js')
  };
} else {
  setGlobal('svs.components.lbUtils.campaignHandler.getCampaign', getCampaign);
}


 })(window);